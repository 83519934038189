import React from 'react';
import {useOutlet} from 'reconnect.js';
import * as Generic from '../../Generic';
import {Switch} from 'antd';
import {navigate} from 'gatsby';
import {Uploader} from '../../Components/Uploader';
import moment from 'moment';

const FormSpec = {
  schema: {
    title: '',
    type: 'object',
    required: [
      'title',
      'subtitle',
      'context',
      'startDate',
      'endDate',
      'priority',
      'isPublished',
      'imgUrl',
    ],
    properties: {
      title: {type: 'string', title: '標題'},
      subtitle: {type: 'string', title: '副標題'},
      context: {type: 'string', title: '內文'},
      startDate: {
        title: '起日',
        type: 'string',
        format: 'date-time',
        default: new Date().toISOString(),
      },
      endDate: {
        title: '迄日',
        type: 'string',
        format: 'date-time',
        default: new Date().toISOString(),
      },
      imgUrl: {type: 'string', title: '圖片'},
      priority: {type: 'number', title: '權重', default: 0},
      isPublished: {type: 'boolean', title: '上架', default: false},
    },
  },
  uiSchema: {
    imgUrl: {
      'ui:widget': Uploader,
    },
    context: {
      'ui:widget': 'textarea',
    },
  },
};

function Dashboard(props) {
  const [actions] = useOutlet('actions');

  return (
    <Generic.Resource
      spec={{
        path: '/admin/announcements',
        name: '公告',
        primaryKey: 'id',
        actions: {
          setLoading: actions.setLoading,
          fetchRecords: actions.fetchAnnouncementList,
          fetchRecordById: actions.fetchAnnouncementById,
          deleteRecordById: actions.deleteAnnouncement,
        },
        searchFields: [],
        columns: [
          {
            title: '標題',
            key: 'title',
            dataIndex: 'title',
          },
          {
            title: '權重',
            key: 'priority',
            dataIndex: 'priority',
            sorter: (a, b) => a.priority - b.priority,
          },
          {
            title: '圖片',
            key: 'imgUrl',
            dataIndex: 'imgUrl',
            render: (value) => {
              return (
                <img
                  style={{width: 100, height: 100, objectFit: 'cover'}}
                  src={value}
                  alt={value}
                />
              );
            },
          },
          {
            title: '起日',
            key: 'startDate',
            dataIndex: 'startDate',
            render: (value) => {
              return <div>{moment(value).format('YYYY-MM-DD HH:MM')}</div>;
            },
          },
          {
            title: '迄日',
            key: 'endDate',
            dataIndex: 'endDate',
            render: (value) => {
              return <div>{moment(value).format('YYYY-MM-DD HH:MM')}</div>;
            },
          },
          {
            title: '上架',
            key: 'isPublished',
            dataIndex: 'isPublished',
            render: (value) => {
              return <Switch defaultChecked={value} disabled />;
            },
          },
        ],
      }}
      renderDetail={(props) => {
        const {instance} = props;
        return (
          <Generic.Form
            showErrorList={false}
            schema={FormSpec.schema}
            uiSchema={FormSpec.uiSchema}
            instance={instance}
            onSubmit={async (formData) => {
              const {id} = formData;
              if (!!id) {
                await actions.updateAnnouncement({id, values: formData});
                await navigate('/admin/announcements');
              } else {
                await actions.createAnnouncement(formData);
                await navigate('/admin/announcements');
              }
            }}
          />
        );
      }}
      {...props}
    />
  );
}

export default Dashboard;
